import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"

const Impressum = () => (
  <Layout page={"impressum"}>
    <SEO title="Impressum"/>
    <div className="wrapper">
      <div data-srgrid-row="">
        <div data-srgrid-col="xs-24 m-12">
          <h1 className={"h1"}>Impressum & Allgemeine Datenschutzerklärung</h1>
        </div>
        <div data-srgrid-col="xs-24 m-6 m-offset-6">
          <div className={"impressum__contact"}>
            <h3 className={"h3"}>Max Kugel</h3>
            <p className={"p"}>Bonner Talweg 34</p>
            <p className={"p"}>53113 Bonn</p>
          </div>
          <br/>
          <div className={"impressum__contact"}>
            <h3 className={"h3"}>Design.</h3>
            <p className={"p"}>Mählerbrandt</p>
            <p className={"p"}>Carina Mähler & Dominic Brandt</p>
            <p className={"p"}><a href={"https://www.maehlerbrandt.com"}>www.maehlerbrandt.com</a></p>
          </div>
          <br/>
          <div className={"impressum__contact"}>
            <h3 className={"h3"}>Fotos.</h3>
            <p className={"p"}>Johannes Dreuw</p>
            <p className={"p"}><a href={"https://www.johannesdreuw.com"}>www.johannesdreuw.com</a></p>
          </div>
        </div>
      </div>
      <div data-srgrid-row="" className={"impressum__content"}>
        <div data-srgrid-col="xs-24 m-18">
          <h3 className={"h3"}>Allgemeine Datenschutzerklärung.</h3>
          <p className={"p"}>
            Durch die Nutzung unserer Website erklären Sie sich mit der Erhebung, Verarbeitung und Nutzung von Daten
            gemäß der nachfolgenden Beschreibung einverstanden. Unsere Website kann grundsätzlich ohne Registrierung
            besucht werden. Dabei werden Daten wie beispielsweise aufgerufene Seiten bzw. Namen der abgerufenen Datei,
            Datum und Uhrzeit zu statistischen Zwecken auf dem Server gespeichert, ohne dass diese Daten unmittelbar auf
            Ihre Person bezogen werden. Personenbezogene Daten, insbesondere Name, Adresse oder E-Mail-Adresse werden
            soweit möglich auf freiwilliger Basis erhoben. Ohne Ihre Einwilligung erfolgt keine Weitergabe der Daten an
            Dritte.
          </p>
        </div>
        <div data-srgrid-col="xs-24 m-18">
          <h3 className={"h3"}>Datenschutzerklärung für Cookies.</h3>
          <p className={"p"}>
            Unsere Website verwendet Cookies. Das sind kleine Textdateien, die es möglich machen, auf dem Endgerät des
            Nutzers spezifische, auf den Nutzer bezogene Informationen zu speichern, während er die Website nutzt.
            Cookies ermöglichen es, insbesondere Nutzungshäufigkeit und Nutzeranzahl der Seiten zu ermitteln,
            Verhaltensweisen der Seitennutzung zu analysieren, aber auch unser Angebot kundenfreundlicher zu gestalten.
            Cookies bleiben über das Ende einer Browser-Sitzung gespeichert und können bei einem erneuten Seitenbesuch
            wieder aufgerufen werden. Wenn Sie das nicht wünschen, sollten Sie Ihren Internetbrowser so einstellen, dass
            er die Annahme von Cookies verweigert.
          </p>
        </div>

        <div data-srgrid-col="xs-24 m-18">
          <h3 className={"h3"}>Haftung für Inhalte.</h3>
          <p className={"p"}>
            Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und
            Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7
            Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis
            10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
            Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
            bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer
            konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
            Inhalte umgehend entfernen.
          </p>
        </div>

        <div data-srgrid-col="xs-24 m-18">
          <h3 className={"h3"}>Haftung für Links.</h3>
          <p className={"p"}>
            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
            Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
            Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
            wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
            Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
            jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
            Rechtsverletzungen werden wir derartige Links umgehend entfernen.
          </p>
        </div>

        <div data-srgrid-col="xs-24 m-18">
          <h3 className={"h3"}>Urheberrecht.</h3>
          <p className={"p"}>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
            Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
            Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit
            die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet.
            Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
            Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
          </p>
        </div>

        <div data-srgrid-col="xs-24 m-18">
          <h3 className={"h3"}>Datenschutz.</h3>
          <p className={"p"}>
            Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf
            unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden,
            erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
            Zustimmung nicht an Dritte weitergegeben.
            <br/>
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
            Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
            möglich.
            <br/>
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung
            von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich
            widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der
            unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
          </p>
        </div>

      </div>
    </div>
  </Layout>
)

export default Impressum